import './index.css'
import { infoContacts } from '../../utils/constants'
import { SocialIcon } from 'react-social-icons'

const Contact = ({ basenamePage, setPage, size, open, animate }) => {
  if (size === 'xs' || size === 'sm' || size === 'md') {
    return (
      <div
        style={{
          display: animate ? 'none' : 'flex'
        }}
        id={`v-${basenamePage}-section-mid`}
      >
        <div id={`v-${basenamePage}-section-mid-top`}>
          <div id={`v-${basenamePage}-section-mid-top-box`}>
            <div id={`v-${basenamePage}-section-mid-top-box-line1`}>
              <span>{'Informações de contato'}</span>
            </div>
            <div id={`v-${basenamePage}-section-mid-top-box-line2`}>
              {infoContacts.map((item, index) => {
                return (
                  <div
                    key={`v-${basenamePage}-key-card-${index}`}
                    id={`v-${basenamePage}-section-mid-top-box-card`}
                    style={
                      index <= infoContacts.length - 1
                        ? { marginBottom: '20px' }
                        : {}
                    }
                  >
                    {item.map((objeto, indexOjbect) => {
                      return (
                        <div
                          key={`v-${basenamePage}-key-card-item-${indexOjbect}`}
                          id={`v-${basenamePage}-card-item`}
                          style={
                            indexOjbect === 0
                              ? { marginTop: '30px' }
                              : indexOjbect === item.length - 1
                                ? { marginBottom: '30px' }
                                : {}
                          }
                        >
                          <span id={`v-${basenamePage}-card-item-title`}>
                            {objeto.title}
                          </span>
                          {objeto.link ? (
                            <SocialIcon
                              key={`v-${basenamePage}-social-media-${indexOjbect}`}
                              url={objeto.value}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ width: '20px', height: '20px' }}
                              bgColor="#ff9900"
                            />
                          ) : (
                            <span id={`v-${basenamePage}-card-item-value`}>
                              {objeto.value}
                            </span>
                          )}
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div id={`v-${basenamePage}-section-mid-bot`}>
          <div id={`v-${basenamePage}-section-mid-bot-box`}>
            <div id={`v-${basenamePage}-section-mid-bot-text`}>
              <span>{'© 2022 Lucas Emanuel'}</span>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        style={{
          display: animate ? 'none' : 'flex'
        }}
        id={`${basenamePage}-section-mid`}
      >
        <div id={`${basenamePage}-section-mid-top`}>
          <div id={`${basenamePage}-section-mid-top-box`}>
            <div id={`${basenamePage}-section-mid-top-box-line1`}>
              <span>{'Informações de contato'}</span>
            </div>
            <div id={`${basenamePage}-section-mid-top-box-line2`}>
              {infoContacts.map((item, index) => {
                return (
                  <div
                    key={`${basenamePage}-key-card-${index}`}
                    id={`${basenamePage}-section-mid-top-box-card`}
                    style={
                      index === 0 || index === 1
                        ? { width: open ? '25%' : '25%' }
                        : { width: open ? '45%' : '40%' }
                    }
                  >
                    {item.map((objeto, indexOjbect) => {
                      return (
                        <div
                          key={`${basenamePage}-key-card-item-${indexOjbect}`}
                          id={`${basenamePage}-card-item`}
                          style={
                            indexOjbect === 0
                              ? { marginTop: '30px' }
                              : indexOjbect === item.length - 1
                                ? { marginBottom: '30px' }
                                : {}
                          }
                        >
                          <span id={`${basenamePage}-card-item-title`}>
                            {objeto.title}
                          </span>
                          {objeto.link ? (
                            <SocialIcon
                              key={`${basenamePage}-social-media-${indexOjbect}`}
                              url={objeto.value}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ width: '20px', height: '20px' }}
                              bgColor="#ff9900"
                            />
                          ) : (
                            <span id={`${basenamePage}-card-item-value`}>
                              {objeto.value}
                            </span>
                          )}
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div id={`${basenamePage}-section-mid-mid`}>
          <div id={`${basenamePage}-section-mid-mid-box`}></div>
        </div>
        <div id={`${basenamePage}-section-mid-bot`}>
          <div id={`${basenamePage}-section-mid-bot-box`}>
            <div id={`${basenamePage}-section-mid-bot-text`}>
              <span>{'© 2022 Lucas Emanuel'}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact
